<template>
    <v-container>
    </v-container>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
    components: {

    },

    methods: {
        ...mapMutations(['setClient']),
        ...mapGetters(['getClient'])
    },

    mounted() {
        if(this.$route.params.client.length == 12){
            this.setClient(this.$route.params.client)
            this.$router.push({path: 'control'})
        } else {
            this.$router.push({path: '/'})
        }
    }
}
</script>